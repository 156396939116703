import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _536a2839 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7f17f792 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _1ed6f837 = () => interopDefault(import('../pages/_lang/_productgroup/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/index" */))
const _60e3b577 = () => interopDefault(import('../pages/_lang/_productgroup/combination/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/combination/index" */))
const _80def982 = () => interopDefault(import('../pages/_lang/_productgroup/literature/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/literature/index" */))
const _42092c01 = () => interopDefault(import('../pages/_lang/_productgroup/_type/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/index" */))
const _63ce6f11 = () => interopDefault(import('../pages/_lang/_productgroup/_type/_subject/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/_subject/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _536a2839,
    name: "index"
  }, {
    path: "/:lang",
    component: _7f17f792,
    name: "lang"
  }, {
    path: "/:lang/:productgroup",
    component: _1ed6f837,
    name: "lang-productgroup"
  }, {
    path: "/:lang/:productgroup/combination",
    component: _60e3b577,
    name: "lang-productgroup-combination"
  }, {
    path: "/:lang/:productgroup/literature",
    component: _80def982,
    name: "lang-productgroup-literature"
  }, {
    path: "/:lang/:productgroup/:type",
    component: _42092c01,
    name: "lang-productgroup-type"
  }, {
    path: "/:lang/:productgroup/:type/:subject",
    component: _63ce6f11,
    name: "lang-productgroup-type-subject"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
